import { Controller } from '@hotwired/stimulus';
import ApexCharts from 'apexcharts';
import {
  getCSSColor,
  euroFormatter,
  defaultFormatter,
  buildChartClickDestination,
} from '../../utils';
import HslGradient from '../../hsl_gradient';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = ['chart'];

  static values = {
    series: Array,
    unit: {
      default: 'euro',
    },
  };

  initialize() {
    this.primaryColor = getCSSColor('primary');
    this.accentColor = getCSSColor('accent1');
    this.lightColor = getCSSColor('accent3');

    this.finalColors = this.hasColorsValue
      ? this.colorsValue.map((name) => getCSSColor(name))
      : [this.primaryColor, this.accentColor, this.lightColor];

    this.idealHeight = this.chartTarget.parentElement.clientHeight;
  }

  connect() {
    if (this.seriesValue.length) {
      this.formatter = this.unitValue === 'euro' ? euroFormatter : defaultFormatter;

      this.formattedSeriesValue = this.formatSeriesValue();

      this.chart = new ApexCharts(this.chartTarget, this.options);
      this.chart.render();
    } else {
      this.chartTarget.innerHTML = '<span class="mx-auto d-block text-center text-muted"> Aucune donnée à afficher</span>';
    }
  }

  formatSeriesValue() {
    // Pour obtenir une couleur différente selon le nombre de tiers dans le code,
    // il faut créer des séries différentes, par nombre de tiers
    // Group data points by providers_count
    const groupedByProvidersCount = this.seriesValue.reduce((acc, point) => {
      const count = point.providers_count;
      if (!acc[count]) {
        acc[count] = [];
      }
      acc[count].push(point);
      return acc;
    }, {});

    // Convert to array of series
    return Object.entries(groupedByProvidersCount).map(([count, data]) => ({
      name: `${count} prestataire${count > 1 ? 's' : ''}`,
      data,
    }));
  }

  // Une couleur pour chaque série, dégradé du vert au rouge
  generateColors() {
    const green = getComputedStyle(document.documentElement).getPropertyValue('--bs-success');
    const red = getComputedStyle(document.documentElement).getPropertyValue('--bs-danger');

    if (this.formattedSeriesValue.length < 2) {
      return [green];
    }

    const { gradient } = new HslGradient(green, red, this.formattedSeriesValue.length);

    console.log('gradient', gradient);
    return gradient;
  }

  get options() {
    const opts = {
      series: this.formattedSeriesValue,
      chart: {
        height: 350,
        type: 'scatter',
        zoom: {
          enabled: true,
          type: 'xy',
        },
        events: {
          mounted: this.showColorLegend(),
          dataPointSelection: (event, chartContext, opts) => {
            console.log('click', opts);
            const { series, seriesIndex, dataPointIndex } = opts;
            const pointData = this.formattedSeriesValue[seriesIndex].data[dataPointIndex];

            console.log('pointData', pointData);
            const destination = buildChartClickDestination(
              `nomenclature_code/${pointData.code_id}`,
              true,
              'all',
            );
            window.scrollTo(0, 0); // Obligé d'ajouter ça sinon le scroll est préservé
            Turbo.visit(destination);
          },
        },
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          formatter: (val) => {
            const floatVal = parseFloat(val);
            return this.formatter(floatVal);
          },
        },
      },
      yaxis: {
        tickAmount: 7,
        labels: {
          formatter: (val) => val.toFixed(1),
        },
        reversed: true,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: this.generateColors(),
      tooltip: {
        x: {
          show: true,
          formatter: (value, data) => {
            const { series, seriesIndex, dataPointIndex } = data;
            const pointData = this.formattedSeriesValue[seriesIndex].data[dataPointIndex];
            return pointData.code_number;
          },
        },
        y: {
          formatter: (value, {
            series, seriesIndex, dataPointIndex, w,
          }) => {
            const pointData = this.formattedSeriesValue[seriesIndex].data[dataPointIndex];
            return `
              <span class="fw-normal">
                Libellé du code: ${pointData.code_label}<br />
                Montant noté: ${euroFormatter(pointData.x)} <br />
                Note de satisfaction: ${pointData.y} <br />
                Nombre de prestataires notés: ${pointData.providers_count}
              </span>
            `;
          },
          title: {
            formatter: () => '',
          },
        },
        marker: {
          show: false,
        },
      },
    };

    return opts;
  }

  showColorLegend() {
    const green = getComputedStyle(document.documentElement).getPropertyValue('--bs-success');
    const red = getComputedStyle(document.documentElement).getPropertyValue('--bs-danger');
    const { gradient } = new HslGradient(green, red, 6);

    const legendDiv = document.createElement('div');
    legendDiv.classList.add('mt-3', 'text-center', 'd-flex', 'align-items-center', 'justify-content-center', 'gap-2');
    legendDiv.innerHTML = `
      <span>Moins de prestataires</span>
      ${gradient.map((color) => `<span style="height: 10px; width: 10px; border-radius: 10px; background-color: ${color}"></span>`).join('')}
      <span>Plus de prestataires</span>
    `;
    this.chartTarget.insertAdjacentElement('afterend', legendDiv);
  }
}
