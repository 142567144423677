import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['star'];

  static values = {
    currentNote: {
      type: Number,
      default: 0,
    },
  };

  connect() {
  }

  onLeave(event) {
    this.toggleClasses(this.currentNoteValue - 1);
  }

  updateStars(event) {
    const currentStarIndex = event.params.index;
    this.toggleClasses(currentStarIndex);
  }

  toggleClasses(currentIndex) {
    this.starTargets.forEach((star, index) => {
      if (index <= currentIndex) {
        star.classList.replace('bi-star', 'bi-star-fill');
      } else {
        star.classList.replace('bi-star-fill', 'bi-star');
      }
    });
  }
}
