export default class HslGradient {
  constructor(startColor, endColor, steps) {
    this.generateHSLGradientHex(startColor, endColor, steps);
  }

  hexToHSL = (hex) => {
    // Convertir HEX en RGB
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    // Calculer la luminosité
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h; let s; let
      l;
    l = (max + min) / 2;

    // Calculer la saturation
    if (max === min) {
      h = s = 0; // Gris
    } else {
      const delta = max - min;
      s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
      switch (max) {
        case r: h = (g - b) / delta + (g < b ? 6 : 0); break;
        case g: h = (b - r) / delta + 2; break;
        case b: h = (r - g) / delta + 4; break;
      }
      h *= 60;
    }

    return {
      h: h || 0,
      s: s * 100,
      l: l * 100,
    };
  };

  hslToHex = (h, s, l) => {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c / 2;
    let r = 0; let g = 0; let
      b = 0;

    if (h >= 0 && h < 60) {
      r = c; g = x; b = 0;
    } else if (h >= 60 && h < 120) {
      r = x; g = c; b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0; g = c; b = x;
    } else if (h >= 180 && h < 240) {
      r = 0; g = x; b = c;
    } else if (h >= 240 && h < 300) {
      r = x; g = 0; b = c;
    } else if (h >= 300 && h < 360) {
      r = c; g = 0; b = x;
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  generateHSLGradientHex = (startHex, endHex, steps) => {
    if (steps < 2) {
      throw new Error("Le nombre d'étapes doit être au moins 2.");
    }

    // Convertir les couleurs hexadécimales en HSL
    const startHSL = this.hexToHSL(startHex);
    const endHSL = this.hexToHSL(endHex);

    // Générer les couleurs intermédiaires
    const gradient = [];
    for (let i = 0; i < steps; i++) {
      const t = i / (steps - 1); // Fraction de progression
      const h = startHSL.h + t * (endHSL.h - startHSL.h); // Interpolation linéaire pour H
      const s = startHSL.s + t * (endHSL.s - startHSL.s); // Interpolation linéaire pour S
      const l = startHSL.l + t * (endHSL.l - startHSL.l); // Interpolation linéaire pour L
      gradient.push(this.hslToHex(h, s, l));
    }
    this.gradient = gradient;
  };
}
